export default defineNuxtRouteMiddleware((_, from) => {
  if (from.path === '/signin') {
    const session = useSupabaseSession();
    if (
      session.value?.expires_at &&
      session.value.expires_at > Math.floor(Date.now() / 1000)
    ) {
      return navigateTo('/chat');
    }
  }
});
